import { makeStyles } from "@material-ui/core/styles";

import FontAwesomeIcon from "./FontAwesomeIcon";

const useStyles = makeStyles((theme) => ({
  dragHandle: {
    // minWidth: "30px",
    padding: 0,
    margin: 0,
    color: theme.palette.primary.light,
    fontWeight: 900,
    fontSize: "1rem",
  },
}));

const DragHandle = ({ dragHandleProps }) => {
  const classes = useStyles();
  return (
    <div className={classes.dragHandle} {...dragHandleProps}>
      {/* <FontAwesomeIcon iconName="sort-alt" /> */}
      <FontAwesomeIcon iconName="grip-lines" />
    </div>
  );
};

export default DragHandle;
