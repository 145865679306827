import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";

import APP_ROUTES from "./routes-config";
import { PATH_SIGN_IN } from "./paths";
import { APP_THEME } from "./utils/app-theme";
import { checkSignIn, signOut } from "./utils/auth";
import { SEVERITY_ERROR, SEVERITY_SUCCESS } from "./constants";

import FeedbackMessage from "./components/FeedbackMessage";
import Spinner from "./components/Spinner";
import RenderRoutes from "./components/RenderRoutes";
import Wrapper from "./components/Wrapper";
import Header from "./components/Header";

const App = () => {
  const [isWorking, setIsWorking] = React.useState(true);
  const [isSignedIn, setIsSignedIn] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState(null);

  const { feedbackMessage } = useSelector((state) => state.app);

  React.useEffect(() => {
    const checkIsSignedIn = async () => {
      const result = await checkSignIn();
      setIsSignedIn(result.isSignedIn);
      setCurrentUser(result.user);
      setIsWorking(false);
    };
    checkIsSignedIn();
  }, []);

  const onSignOutClick = () => {
    const doSignOut = async () => {
      signOut();
      setIsSignedIn(false);
      setCurrentUser(null);
    };
    doSignOut();
  };

  if (!isWorking) {
    return (
      <ThemeProvider theme={APP_THEME}>
        <Router>
          <Header
            isSignedIn={isSignedIn}
            onSignOutClick={onSignOutClick}
            currentUser={currentUser}
          />
          <Wrapper>
            {feedbackMessage && (
              <FeedbackMessage
                key={feedbackMessage.key}
                message={feedbackMessage.message}
                severity={
                  feedbackMessage.isError ? SEVERITY_ERROR : SEVERITY_SUCCESS
                }
              />
            )}
            <RenderRoutes
              routes={APP_ROUTES}
              defaultRedirectPath={PATH_SIGN_IN}
              isSignedIn={isSignedIn}
              setIsSignedIn={setIsSignedIn}
              setCurrentUser={setCurrentUser}
            />
          </Wrapper>
        </Router>
      </ThemeProvider>
    );
  }

  return <Spinner />;
};

export default App;
