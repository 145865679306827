import React from "react";
import { Redirect, Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import { createAccount, createUser } from "../api/api-requests";
import {
  signIn,
  checkSignIn,
  updateAccountCreatedParam,
  updateUserCreatedParam,
  getCurrentUser,
} from "../utils/auth";

import { actionSetFeedbackMessage } from "../store/app-actions";

import SignInForm from "./SignInForm";
import { PATH_HOME, PATH_REGISTER, PATH_FORGOT_PASSWORD } from "../paths";

const SignIn = (props) => {
  const reduxDispatch = useDispatch();
  const { isSignedIn, setIsSignedIn, setCurrentUser } = props;

  const [doingSignInCheck, setDoingSignInCheck] = React.useState(true);

  React.useEffect(() => {
    const checkIfUserSignedIn = async () => {
      setDoingSignInCheck(true);
      let result = await checkSignIn();
      setIsSignedIn(result.isSignedIn);
      setCurrentUser(result.user);
      setDoingSignInCheck(false);
    };
    checkIfUserSignedIn();
  }, [setIsSignedIn, setCurrentUser]);

  const onSubmit = ({ email, password }) => {
    const doSignIn = async () => {
      const result = await signIn(email, password);
      if (result.error) {
        reduxDispatch(actionSetFeedbackMessage(result.error, true));
      } else {
        if (result.isSignedIn) {
          if (result.user["custom:account_created"] === "N") {
            await createAccount();
            await updateAccountCreatedParam();
          }

          if (result.user["custom:user_created"] === "N") {
            await createUser(result.user.email, result.user.name);
            await updateUserCreatedParam();
          }

          result.user = await getCurrentUser();
        }

        setIsSignedIn(result.isSignedIn);
        setCurrentUser(result.user);
      }
    };
    doSignIn();
  };

  if (!doingSignInCheck && isSignedIn) {
    return <Redirect to={PATH_HOME} />;
  }

  if (!doingSignInCheck && !isSignedIn) {
    return (
      <Container component="main" maxWidth="xs">
        <Typography variant="h3">Sign In</Typography>
        <SignInForm onSubmit={onSubmit} />
        <Grid container>
          <Grid item xs>
            <Typography component="p" variant="body2" gutterBottom>
              <Link to={PATH_FORGOT_PASSWORD} component={RouterLink}>
                Forgot Password?
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="p" variant="body2" gutterBottom>
              <Link to={PATH_REGISTER} component={RouterLink}>
                No account? Register here.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return <></>;
};

export default SignIn;
