import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";

import FontAwesomeIcon from "../components/FontAwesomeIcon";

const useStyles = makeStyles((theme) => ({
  heading: {
    padding: 0,
    margin: 0,
  },
  fab: {
    marginRight: "6px",
  },
}));

const PageHeadBar = ({ heading, onFabClick }) => {
  const classes = useStyles();

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item xs>
        <Typography variant="h2" className={classes.heading}>
          {heading}
        </Typography>
      </Grid>
      <Grid item xs>
        <div style={{ textAlign: "right" }}>
          <Fab
            color="primary"
            size="small"
            onClick={onFabClick}
            className={classes.fab}
          >
            <FontAwesomeIcon iconName={"plus"} />
          </Fab>
        </div>
      </Grid>
    </Grid>
  );
};

export default PageHeadBar;
