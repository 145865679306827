import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { SEVERITY_SUCCESS } from "../constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FeedbackMessage = ({ message, severity = SEVERITY_SUCCESS }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message]);

  const handleClose = (event, reason) => {
    if (open) {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={severity === SEVERITY_SUCCESS ? 3000 : 5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default FeedbackMessage;
