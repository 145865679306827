import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import List from "@material-ui/core/List";

import DraggableTask from "./DraggableTask";

const TaskList = ({
  list,
  onDragEnded,
  openUpdateDialogClick,
  onCompleteClick,
}) => {
  return (
    <>
      <DragDropContext onDragEnd={onDragEnded}>
        <Droppable droppableId="dropArea">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ marginTop: "10px" }}
            >
              <List>
                {list.taskIds.map((taskId, idx) => {
                  return (
                    <Draggable key={taskId} draggableId={taskId} index={idx}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <DraggableTask
                            key={taskId}
                            isDragging={snapshot.isDragging}
                            dragHandleProps={provided.dragHandleProps}
                            onEditClick={openUpdateDialogClick}
                            task={list.tasks[taskId]}
                            onCompleteClick={onCompleteClick}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </List>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default TaskList;
