export const PATH_SIGN_IN = "/sign-in";
export const PATH_SIGN_OUT = "/sign-out";

export const PATH_REGISTER = "/register";
export const PATH_REGISTER_CONFIRM = "/register-confirmation";
export const PATH_REGISTER_CONFIRM_RESEND_CODE =
  "/resend-register-confirmation-code";

export const PATH_FORGOT_PASSWORD = "/forgot-password";
export const PATH_FORGOT_PASSWORD_RESEND_CODE = "/resend-forgot-password-code";

export const PATH_HOME = "/";
export const PATH_COMPLETED_TASKS = "/completed";
export const PATH_SETTINGS = "/settings";
