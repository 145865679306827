import React, { useState } from "react";
import { Redirect, Link as RouterLink, useLocation } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";

import { PATH_SIGN_IN } from "../paths";
import { confirmRegistrationSignUp } from "../utils/auth";
import { extractQueryString } from "../utils/general";

import RegisterConfirmForm from "./RegisterConfirmForm";

const RegisterConfirm = () => {
  const { search } = useLocation();

  const [email, setEmail] = useState(null);
  const [isWorking, setIsWorking] = useState(true);
  const [redirect, setRedirect] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  React.useEffect(() => {
    const qsParams = extractQueryString(search);

    if ("email" in qsParams) {
      setEmail(qsParams.email);
    }

    setIsWorking(false);
  }, [search]);

  const onConfirmSubmit = ({ email, confirmCode }) => {
    const doConfirm = async () => {
      try {
        setIsWorking(true);
        await confirmRegistrationSignUp(email, confirmCode);
        setRedirect(PATH_SIGN_IN);
        setIsConfirmed(true);
      } catch (err) {
        console.log(err);
      } finally {
        setIsWorking(false);
      }
    };

    doConfirm();
  };

  if (!isWorking && isConfirmed && redirect) {
    return <Redirect to={redirect} />;
  }

  if (!isWorking) {
    return (
      <Container component="main" maxWidth="xs">
        <Typography variant="h3">Confirm Registration</Typography>
        <Typography component="p" variant="body2" gutterBottom>
          You should have received an email containing a confirmation code.
        </Typography>
        <RegisterConfirmForm onSubmit={onConfirmSubmit} currentEmail={email} />
        <Divider style={{ marginBottom: "16px" }} />
        <Link to={PATH_SIGN_IN} component={RouterLink}>
          Have an account? Sign In.
        </Link>
      </Container>
    );
  }

  return <></>;
};

export default RegisterConfirm;
