import {
  APP_INITIAL_STATE,
  APP_CLEAR_FEEDBACK_MESSAGE,
  APP_SET_FEEDBACK_MESSAGE,
  APP_SET_WORKING_STATE,
} from "./app-actions";

const appReducer = (state = APP_INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_SET_WORKING_STATE:
    case APP_CLEAR_FEEDBACK_MESSAGE:
    case APP_SET_FEEDBACK_MESSAGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
