import { STATE_IDLE } from "./store-constants";

export const APP_INITIAL_STATE = {
  workingState: STATE_IDLE,
  feedbackMessage: null,
};

export const APP_SET_WORKING_STATE = "APP_SET_WORKING_STATE";
export const APP_SET_FEEDBACK_MESSAGE = "APP_SET_FEEDBACK_MESSAGE";
export const APP_CLEAR_FEEDBACK_MESSAGE = "APP_CLEAR_FEEDBACK_MESSAGE";

export const actionSetFeedbackMessage = (message, isError) => {
  return {
    type: APP_SET_FEEDBACK_MESSAGE,
    payload: {
      feedbackMessage: {
        message: message,
        isError: isError,
        key: new Date(),
      },
    },
  };
};

export const actionClearFeedbackMessage = () => {
  return {
    type: APP_CLEAR_FEEDBACK_MESSAGE,
    payload: {
      feedbackMessage: null,
    },
  };
};

export const actionSetWorkingState = (workingState) => {
  return {
    type: APP_SET_WORKING_STATE,
    payload: {
      workingState: workingState,
    },
  };
};
