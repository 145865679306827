import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

import DragHandle from "../components/DragHandle";
import FontAwesomeIcon from "../components/FontAwesomeIcon";

import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "4px",
    marginBottom: "6px",
    padding: "0px",
    backgroundColor: "#fafafa",
  },
  formInput: {
    margin: 0,
  },
  handle: {
    minWidth: "24px",
    marginTop: "4px",
  },
  checkbox: {
    cursor: "pointer",
  },
}));

const DraggableTask = ({
  task,
  dragHandleProps,
  isDragging,
  onCompleteClick,
}) => {
  const classes = useStyles();

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar className={classes.handle}>
        <DragHandle dragHandleProps={dragHandleProps} />
      </ListItemAvatar>
      <ListItemText>
        <Typography variant="body1">{task.name}</Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <Link
          onClick={() => onCompleteClick(task.id)}
          className={classes.checkbox}
        >
          <Typography
            component="span"
            variant="h6"
            className={classes.checkbox}
          >
            <FontAwesomeIcon iconName="circle" />
          </Typography>
        </Link>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default DraggableTask;
