import React from "react";
import { useForm, Controller } from "react-hook-form";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

import { ActionButton, SubmitButton } from "../components/Buttons";

const TaskDialog = ({
  submitButtonText,
  onSubmit,
  cancelButtonText,
  onCancel,
  task,
  isWorking,
}) => {
  const { handleSubmit, control } = useForm();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={() => onCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            defaultValue={task ? task.name : ""}
            rules={{ required: "You must enter the task name" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Task"
                variant="outlined"
                value={value}
                onChange={onChange}
                fullWidth
                margin="normal"
                error={!!error}
                helperText={error ? error.message : null}
                required
                autoFocus
                multiline
                rowsMax={4}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <ActionButton
            buttonText={cancelButtonText}
            onClickEvent={() => onCancel()}
            color="secondary"
            isDisabled={isWorking}
          />
          <SubmitButton
            buttonText={submitButtonText}
            color="primary"
            isDisabled={isWorking}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TaskDialog;
