import React, { useState } from "react";
import { Redirect, Link as RouterLink } from "react-router-dom";

import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import { PATH_REGISTER_CONFIRM, PATH_SIGN_IN } from "../paths";
import { register } from "../utils/auth";
import { getShortUUID } from "../utils/general";

import RegisterForm from "./RegisterForm";

const Register = (props) => {
  const [isWorking, setIsWorking] = useState(null);
  const [redirect, setRedirect] = useState(null);

  const onRegisterSubmit = ({ name, email, password }) => {
    const doRegister = async () => {
      try {
        setIsWorking(true);
        const tempAccountId = getShortUUID();
        const user = register(tempAccountId, name, email, password);
        if (user) {
          // reduxDispatch(
          //   reduxSetFeedbackMessage("Registration successful.", false)
          // );
          setRedirect(`${PATH_REGISTER_CONFIRM}?email=${email}`);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsWorking(false);
      }
    };
    doRegister();
  };

  if (!isWorking && redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Typography variant="h3">Register</Typography>
      <RegisterForm onSubmit={onRegisterSubmit} isWorking={isWorking} />
      <Divider style={{ marginBottom: "16px" }} />

      <Grid container>
        <Grid item xs>
          <Typography component="p" variant="body2" gutterBottom>
            <Link to={PATH_SIGN_IN} component={RouterLink}>
              Have an account? Sign In.
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="p" variant="body2" gutterBottom>
            <Link to={PATH_REGISTER_CONFIRM} component={RouterLink}>
              Confirmation code?
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Register;
