import { Auth } from "aws-amplify";

export const ENDPOINT_NAME = "protectedEndpoint";

const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_API_ENDPOINT,
} = process.env;

// authentication:
export const amplify_auth_config = {
  region: REACT_APP_COGNITO_REGION,
  userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID,
};

// api:
export const amplify_api_config = {
  endpoints: [
    {
      name: ENDPOINT_NAME,
      endpoint: REACT_APP_API_ENDPOINT,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
  ],
};
