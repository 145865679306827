import { Link as RouterLink } from "react-router-dom";

import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

import FontAwesomeIcon from "./FontAwesomeIcon";

import {
  PATH_HOME,
  PATH_COMPLETED_TASKS,
  PATH_SETTINGS,
  PATH_SIGN_OUT,
} from "../paths";

export const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  anchorLink: {
    // color: "rgba(0, 0, 0, 0.54)",
    textDecoration: "none",
    fontSize: "0.9rem",
  },
  listItemIcon: {
    minWidth: "36px",
  },
}));

const NavigationItem = ({ itemText, iconName, linkPath }) => {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemIcon className={classes.listItemIcon}>
        <FontAwesomeIcon iconName={iconName} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Link
            to={linkPath}
            component={RouterLink}
            className={classes.anchorLink}
          >
            {itemText}
          </Link>
        }
      />
    </ListItem>
  );
};

const MainNavigation = () => {
  return (
    <List>
      <NavigationItem itemText="Home" iconName="home" linkPath={PATH_HOME} />
      <NavigationItem
        itemText="Add New List"
        iconName="tasks"
        linkPath={PATH_COMPLETED_TASKS}
      />
      <NavigationItem
        itemText="Completed Tasks"
        iconName="tasks"
        linkPath={PATH_COMPLETED_TASKS}
      />
      {/* <NavigationItem
        itemText="Settings"
        iconName="cog"
        linkPath={PATH_SETTINGS}
      /> */}
      <NavigationItem
        itemText="Sign Out"
        iconName="sign-out-alt"
        linkPath={PATH_SIGN_OUT}
      />
    </List>
  );
};

export default MainNavigation;
