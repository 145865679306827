import { Auth } from "aws-amplify";

export const COGNITO_CHALLENGE_PWD_REQUIRED = "NEW_PASSWORD_REQUIRED";
export const COGNITO_ERROR_NOT_AUTHORIZED = "NotAuthorizedException";
export const COGNITO_ERROR_USER_NOT_FOUND = "UserNotFoundException";
export const COGNITO_ERROR_USERNAME_EXISTS = "UsernameExistsException";
export const COGNITO_ERROR_INVALID_PARAMETER = "InvalidParameterException";
export const COGNITO_ERROR_EXPIRED_CODE = "ExpiredCodeException";

export function determineCognitoError(err) {
  switch (err.code) {
    case COGNITO_ERROR_USERNAME_EXISTS:
      return "This username has already been registered";

    case COGNITO_ERROR_INVALID_PARAMETER:
      return "There appears to be a problem with your entries. Please check you've entered a valid email address and that the password meets the expected criteria.";

    case COGNITO_ERROR_NOT_AUTHORIZED:
    case COGNITO_ERROR_USER_NOT_FOUND:
      return "Incorrect username or password. Please check your entries and try again.";

    default:
      return err.message;
  }
}

export const SIGN_IN_RESULT = {
  isSignedIn: null,
  user: null,
  cognitoUser: null,
  error: null,
};

export async function signIn(email, password) {
  let result = SIGN_IN_RESULT;

  try {
    let user = await Auth.signIn(email, password);
    result.isSignedIn = true;
    result.user = user.attributes;
  } catch (err) {
    result.error = determineCognitoError(err);
    result.isSignedIn = false;
    result.user = null;
    result.cognitoUser = null;
  }

  return result;
}

export async function signOut() {
  let result = SIGN_IN_RESULT;
  result.isSignedIn = false;

  try {
    await Auth.signOut();
  } catch (err) {
    result.error = determineCognitoError(err);
  }

  return result;
}

export async function checkSignIn() {
  let result = SIGN_IN_RESULT;

  try {
    const user = await getCurrentUser(true);
    result.isSignedIn = true;
    result.user = user.attributes;
  } catch (err) {
    result.isSignedIn = false;
    result.user = null;
    result.error = determineCognitoError(err);
  }

  return result;
}

export async function register(
  accountId,
  name,
  email,
  password,
  accountCreated = "N",
  userCreated = "N"
) {
  return await Auth.signUp({
    username: email,
    password: password,
    attributes: {
      name,
      email,
      "custom:account_id": accountId,
      "custom:account_created": accountCreated,
      "custom:user_created": userCreated,
    },
  });
}

export async function confirmRegistrationSignUp(email, confirmCode) {
  await Auth.confirmSignUp(email, confirmCode);
}

export async function getCurrentUser(bypassCache = false) {
  return await Auth.currentAuthenticatedUser({
    bypassCache: bypassCache,
  });
}

export async function updateAccountCreatedParam() {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, {
    "custom:account_created": "Y",
  });
}

export async function updateUserCreatedParam() {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, {
    "custom:user_created": "Y",
  });
}
