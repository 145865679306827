import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";

import {
  actionAddTaskDialogState,
  actionCreateTask,
  actionReOrderTasks,
  actionSetWorkingState,
  actionCompleteTask,
  DIALOG_STATE_OPEN,
  DIALOG_STATE_CLOSED,
} from "../store/task-actions";
import { STATE_WORKING } from "../store/store-constants";

import FeedbackMessage from "../components/FeedbackMessage";

import PageHeadBar from "./PageHeadBar";
import TaskDialog from "./TaskDialog";
import TaskList from "./TaskList";
import { SEVERITY_ERROR, SEVERITY_SUCCESS } from "../constants";

const ListContainer = ({ list }) => {
  const reduxDispatch = useDispatch();
  const { dialogs, workingState, feedbackMessage } = useSelector(
    (state) => state.task
  );

  const onOpenAddTaskDialog = () => {
    reduxDispatch(actionAddTaskDialogState(DIALOG_STATE_OPEN));
  };

  const onCloseAddTaskDialog = () => {
    reduxDispatch(actionAddTaskDialogState(DIALOG_STATE_CLOSED));
  };

  const onAddTaskSubmit = (formData) => {
    reduxDispatch(actionSetWorkingState(STATE_WORKING));
    reduxDispatch(actionCreateTask(list.id, formData.name));
  };

  const onCompleteTaskClick = (taskId) => {
    reduxDispatch(actionSetWorkingState(STATE_WORKING));
    reduxDispatch(actionCompleteTask(list.id, taskId));
  };

  const onReorderEnded = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    reduxDispatch(actionSetWorkingState(STATE_WORKING));

    const newTaskIdList = Array.from(list.taskIds);
    newTaskIdList.splice(source.index, 1);
    newTaskIdList.splice(destination.index, 0, draggableId);

    reduxDispatch(actionReOrderTasks(list.id, newTaskIdList));
  };

  const headingText = `${list.taskIds ? list.taskIds.length : 1} ${list.name}`;

  return (
    <>
      <PageHeadBar heading={headingText} onFabClick={onOpenAddTaskDialog} />

      {(!("taskIds" in list) || !list.taskIds || 0 === list.taskIds.length) && (
        <Typography variant="body1" color="primary">
          Woohoo! No pending tasks. Put your feet up; you've earned it.
        </Typography>
      )}

      {list.taskIds.length > 0 && (
        <>
          <TaskList
            list={list}
            onCompleteClick={onCompleteTaskClick}
            onDragEnded={onReorderEnded}
          />
        </>
      )}

      {dialogs.addTaskDialogState === DIALOG_STATE_OPEN && (
        <TaskDialog
          cancelButtonText="cancel"
          onCancel={onCloseAddTaskDialog}
          submitButtonText="save"
          onSubmit={onAddTaskSubmit}
          title="Add New Task"
          isWorking={workingState === STATE_WORKING}
        />
      )}

      {feedbackMessage && (
        <FeedbackMessage
          key={feedbackMessage.key}
          message={feedbackMessage.message}
          severity={feedbackMessage.isError ? SEVERITY_ERROR : SEVERITY_SUCCESS}
        />
      )}
    </>
  );
};

export default ListContainer;
