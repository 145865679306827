import {
  PATH_REGISTER,
  PATH_REGISTER_CONFIRM,
  PATH_HOME,
  // PATH_COMPLETED_TASKS,
} from "./paths";

import Register from "./register/index";
import RegisterConfirm from "./register/RegisterConfirm";
import Dashboard from "./dashboard/index";
// import TaskListContainer from "./tasks/TaskListContainer";
// import CompletedTasksContainer from "./tasks/CompletedTasks";

const APP_ROUTES = [
  {
    path: PATH_REGISTER,
    exact: true,
    key: "register",
    protected: false,
    component: Register,
  },
  {
    path: PATH_REGISTER_CONFIRM,
    exact: true,
    key: "register-confirm",
    protected: false,
    component: RegisterConfirm,
  },
  {
    path: PATH_HOME,
    exact: true,
    key: "task-list-container",
    protected: true,
    component: Dashboard,
  },
  // {
  //   path: PATH_COMPLETED_TASKS,
  //   exact: true,
  //   key: "completed-tasks",
  //   protected: true,
  //   component: CompletedTasksContainer,
  // },
];

export default APP_ROUTES;
