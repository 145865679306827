import React from "react";

import Typography from "@material-ui/core/Typography";
import { green, red } from "@material-ui/core/colors";

import FontAwesomeIcon from "./FontAwesomeIcon";

import {
  longEnough,
  containsLowercaseLetter,
  containsNumber,
  containsUppercaseLetter,
  containsSpecialCharacter,
} from "../utils/form-helpers";

const CheckItem = ({ isValid, displayText }) => {
  return (
    <>
      <Typography variant="caption" display="block" gutterBottom>
        {isValid ? (
          <FontAwesomeIcon iconName="check" style={{ color: green[500] }} />
        ) : (
          <FontAwesomeIcon iconName="check" style={{ color: red[500] }} />
        )}{" "}
        {displayText}
      </Typography>
    </>
  );
};

const ValidPasswordDisplay = ({ value, confirmValue = null }) => {
  return (
    <small>
      Passwords must:
      <CheckItem
        isValid={longEnough(value)}
        displayText="be at least 8 characters long"
      />
      <CheckItem
        isValid={containsLowercaseLetter(value)}
        displayText="contain at least one lowercase letter"
      />
      <CheckItem
        isValid={containsUppercaseLetter(value)}
        displayText="contain at least one uppercase letter"
      />
      <CheckItem
        isValid={containsNumber(value)}
        displayText="contain at least one number"
      />
      <CheckItem
        isValid={containsSpecialCharacter(value)}
        displayText="contain at least one special character"
      />
      <CheckItem
        isValid={value === confirmValue}
        displayText="match the confirm password value"
      />
    </small>
  );
};

export default ValidPasswordDisplay;
