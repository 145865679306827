import { API } from "aws-amplify";

import { ENDPOINT_NAME } from "../aws-config";
export const pathPrefixAccount = "/accounts";
export const pathPrefixList = "/lists";
export const pathPrefixTask = "tasks";

// helper methods:
const doPost = (url, formData) => {
  return API.post(ENDPOINT_NAME, url, { body: formData });
};

const doPatch = (url, formData) => {
  return API.patch(ENDPOINT_NAME, url, { body: formData });
};

const doGet = (url, querystringParams = null) => {
  return API.get(ENDPOINT_NAME, url, {
    queryStringParameters: querystringParams,
  });
};

const doDelete = (url) => {
  return API.del(ENDPOINT_NAME, url);
};

// Accounts: ------------------------------------
export const createAccount = () => {
  return doPost(pathPrefixAccount);
};

export const createUser = (email, name) => {
  return doPost(`${pathPrefixAccount}/users`, {
    email: email,
    name: name,
  });
};

export const fetchAccount = () => {
  return doGet(pathPrefixAccount);
};

export const fetchAllActiveItems = () => {
  return doGet(`${pathPrefixAccount}/active-items`);
};

// Lists: ------------------------------------
export const fetchAllLists = () => {
  return doGet(pathPrefixList);
};

export const fetchList = (listId) => {
  return doGet(`${pathPrefixList}/${listId}`);
};

export const createList = (name) => {
  return doPost(pathPrefixList, { name: name });
};

export const updateList = (listId, name) => {
  return doPatch(`${pathPrefixList}/${listId}`, { name: name });
};

export const deleteList = (listId) => {
  return doDelete(`${pathPrefixList}/${listId}`);
};

// Tasks: ------------------------------------
export const fetchAllTasks = (listId) => {
  return doGet(`${pathPrefixList}/${listId}/${pathPrefixTask}`);
};

export const fetchCompletedTasks = (listId, startDate) => {
  return doGet(`${pathPrefixList}/${listId}/${pathPrefixTask}/completed`, {
    startDate: startDate,
  });
};

export const fetchTask = (listId, taskId) => {
  return doGet(`${pathPrefixList}/${listId}/${pathPrefixTask}/${taskId}`);
};

export const deleteTask = (listId, taskId) => {
  return doDelete(`${pathPrefixList}/${listId}/${pathPrefixTask}/${taskId}`);
};

export const updateTask = (listId, taskId, taskData) => {
  return doPatch(
    `${pathPrefixList}/${listId}/${pathPrefixTask}/${taskId}`,
    taskData
  );
};

export const completeTask = (listId, taskId, taskData = {}) => {
  return doPatch(
    `${pathPrefixList}/${listId}/${pathPrefixTask}/${taskId}/complete`,
    taskData
  );
};

export const uncompleteTask = (listId, taskId, taskData = {}) => {
  return doPatch(
    `${pathPrefixList}/${listId}/${pathPrefixTask}/${taskId}/uncomplete`,
    taskData
  );
};

export const updateTaskPriorities = (listId, taskData) => {
  return doPatch(
    `${pathPrefixList}/${listId}/${pathPrefixTask}/priorities`,
    taskData
  );
};

export const createTask = (listId, taskData) => {
  return doPost(`${pathPrefixList}/${listId}/${pathPrefixTask}`, taskData);
};
