import {
  TASK_INITIAL_STATE,
  TASK_ACTION_ADD_TASK,
  TASK_ACTION_ADD_TASK_DIALOG_STATE,
  TASK_ACTION_COMPLETE_TASK,
  TASK_ACTION_FETCH_ACTIVE_LISTS_TASKS,
  TASK_ACTION_REORDER_TASKS,
  TASK_ACTION_SET_WORKING_STATE,
  TASK_ACTION_FEEDBACK_MESSAGE,
  DIALOG_STATE_CLOSED,
  TASK_ACTION_SET_INITIAL_LOAD_STATE,
} from "./task-actions";

function addTask(oldState, payload) {
  const newState = {
    ...oldState,
    workingState: payload.workingState,
    feedbackMessage: payload.feedbackMessage,
  };

  if (!("taskIds" in newState.lists[payload.listId])) {
    newState.lists[payload.listId].taskIds = [payload.newTask.id];
  } else {
    newState.lists[payload.listId].taskIds.push(payload.newTask.id);
  }

  // add the task detail to the task lookup in the corresponding list:
  newState.lists[payload.listId].tasks[payload.newTask.id] = payload.newTask;

  // make sure the dialog is closed
  newState.dialogs.addTaskDialogState = DIALOG_STATE_CLOSED;

  return newState;
}

function reorderTasks(oldState, payload) {
  const newState = { ...oldState };
  newState.lists[payload.listId].taskIds = payload.taskIdList;
  return newState;
}

function changeTaskDialogState(oldState, dialogState) {
  const newState = { ...oldState };
  newState.dialogs.addTaskDialogState = dialogState;
  return newState;
}

function completeTask(oldState, payload) {
  const newState = {
    ...oldState,
    workingState: payload.workingState,
    feedbackMessage: payload.feedbackMessage,
  };

  // find the index of the item in the list and remove it
  const taskIds = newState.lists[payload.listId].taskIds;

  const idx = taskIds.indexOf(payload.taskId);
  if (idx > -1) {
    taskIds.splice(idx, 1);
  }

  // remove the task definition from the tasks dictionary
  const tasks = newState.lists[payload.listId].tasks;
  delete tasks[payload.taskId];

  return newState;
}

function setFeedbackMessage(oldState, payload) {
  return {
    ...oldState,
    workingState: payload.workingState,
    feedbackMessage: { message: payload.message, isError: payload.isError },
  };
}

const taskReducer = (state = TASK_INITIAL_STATE, action) => {
  switch (action.type) {
    case TASK_ACTION_ADD_TASK:
      return addTask(state, action.payload);

    case TASK_ACTION_REORDER_TASKS:
      return reorderTasks(state, action.payload);

    case TASK_ACTION_COMPLETE_TASK:
      return completeTask(state, action.payload);

    case TASK_ACTION_ADD_TASK_DIALOG_STATE:
      return changeTaskDialogState(state, action.payload);

    case TASK_ACTION_FEEDBACK_MESSAGE:
      return setFeedbackMessage(state, action.payload);

    case TASK_ACTION_FETCH_ACTIVE_LISTS_TASKS:
    case TASK_ACTION_SET_WORKING_STATE:
    case TASK_ACTION_SET_INITIAL_LOAD_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default taskReducer;
