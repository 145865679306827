import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btn: {
    fontSize: ".8rem",
    marginRight: "6px",
  },
  btnGroup: {
    textAlign: "right",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const ActionButton = ({
  onClickEvent,
  buttonText,
  color = "primary",
  variant = "contained",
  size = "medium",
  isDisabled = false,
  buttonIcon = null,
}) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      size={size}
      variant={variant}
      color={color}
      onClick={onClickEvent}
      disabled={isDisabled}
      className={classes.btn}
      startIcon={buttonIcon}
    >
      {buttonText}
    </Button>
  );
};

export const LinkButton = ({
  path,
  buttonText,
  color,
  size = "medium",
  buttonIcon = null,
  isDisabled = false,
  variant = "contained",
}) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      component={RouterLink}
      to={path}
      size={size}
      disabled={isDisabled}
      variant={variant}
      startIcon={buttonIcon}
      color={color}
      className={classes.btn}
    >
      {buttonText}
    </Button>
  );
};

export const SubmitButton = ({
  buttonText = "Save",
  variant = "contained",
  color = "primary",
  size = "medium",
  isWorking = false,
  isDisabled = false,
  icon = null,
}) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      size={size}
      type="submit"
      variant={variant}
      color={color}
      disabled={isDisabled}
      className={classes.btn}
      startIcon={isWorking ? <CircularProgress size={20} /> : icon}
    >
      {buttonText}
    </Button>
  );
};

export const SubmitWithClickEventButton = ({
  onClick,
  buttonText = "Save",
  variant = "contained",
  color = "primary",
  size = "medium",
  isWorking = false,
  isDisabled = false,
  icon = null,
}) => {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      size={size}
      type="submit"
      variant={variant}
      color={color}
      disabled={isDisabled}
      className={classes.btn}
      startIcon={icon}
      onClick={onClick}
    >
      {isWorking ? <CircularProgress size={20} /> : buttonText}
    </Button>
  );
};

export const ButtonGroup = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.btnGroup}>{children}</div>;
};
