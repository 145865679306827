import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../components/Spinner";
import ListContainer from "./ListContainer";
import ListNameDisplay from "./ListNameDisplay";

import {
  STATE_WORKING,
  STATE_FINISHED,
  STATE_ERROR,
  STATE_IDLE,
} from "../store/store-constants";

import {
  actionFetchActiveListsAndTasks,
  actionSetInitialLoadState,
  actionSetWorkingState,
} from "../store/task-actions";

const Dashboard = () => {
  const reduxDispatch = useDispatch();
  const { orderedListIds, lists, initialLoadState, workingState } = useSelector(
    (state) => state.task
  );

  React.useEffect(() => {
    console.log("Dashboard -> useEffect");
    reduxDispatch(actionSetInitialLoadState(STATE_WORKING));
    reduxDispatch(actionSetWorkingState(STATE_WORKING));
    reduxDispatch(actionFetchActiveListsAndTasks());
  }, [reduxDispatch]);

  const onlyOneList = 1 === orderedListIds.length;

  if (initialLoadState === STATE_FINISHED) {
    return (
      <>
        {onlyOneList && (
          <>
            <ListContainer list={lists[orderedListIds[0]]} />
          </>
        )}

        {!onlyOneList && (
          <>
            {orderedListIds.map((listId) => {
              return (
                <ListContainer key={listId} list={lists[orderedListIds[0]]} />
              );
            })}
          </>
        )}
      </>
    );
  }

  if (workingState === STATE_ERROR) {
    return <div>There was an error retrieving the tasks</div>;
  }

  return <Spinner />;
};

export default Dashboard;
