import {
  fetchAllActiveItems,
  createTask,
  createList,
  completeTask,
  updateTaskPriorities,
} from "../api/api-requests";
import { STATE_IDLE, STATE_ERROR, STATE_FINISHED } from "./store-constants";

import { STATE_WORKING } from "../tasks/taskReducer";

export const DIALOG_STATE_OPEN = "open";
export const DIALOG_STATE_CLOSED = "closed";

// Actions:
export const TASK_ACTION_SET_WORKING_STATE = "TASK_ACTION_SET_WORKING_STATE";
export const TASK_ACTION_SET_INITIAL_LOAD_STATE =
  "TASK_ACTION_SET_INITIAL_LOAD_STATE";
export const TASK_ACTION_FETCH_ACTIVE_LISTS_TASKS =
  "TASK_ACTION_FETCH_ACTIVE_LISTS_TASKS";
export const TASK_ACTION_REORDER_TASKS = "TASK_ACTION_REORDER_TASKS";
export const TASK_ACTION_COMPLETE_TASK = "TASK_ACTION_COMPLETE_TASK";
export const TASK_ACTION_UNCOMPLETE_TASK = "TASK_ACTION_UNCOMPLETE_TASK";
export const TASK_ACTION_ADD_TASK = "TASK_ACTION_ADD_TASK";
export const TASK_ACTION_ADD_TASK_DIALOG_STATE =
  "TASK_ACTION_ADD_TASK_DIALOG_STATE";
export const TASK_ACTION_FEEDBACK_MESSAGE = "TASK_ACTION_FEEDBACK_MESSAGE";

// Initial State:
export const TASK_INITIAL_STATE = {
  initialLoadState: STATE_IDLE,
  workingState: STATE_IDLE,
  orderedListIds: [],
  lists: {},
  dialogs: {
    addTaskDialogState: DIALOG_STATE_CLOSED,
    updateTaskDialogState: DIALOG_STATE_CLOSED,
    addListDialogState: DIALOG_STATE_CLOSED,
    updateListDialogState: DIALOG_STATE_CLOSED,
  },
  feedbackMessage: null,
};

// Actions:
export const actionSetFeedbackMessage = (
  message,
  isError,
  workingState = STATE_ERROR
) => {
  return {
    type: TASK_ACTION_FEEDBACK_MESSAGE,
    payload: {
      message: message,
      isError: isError,
      workingState: workingState,
    },
  };
};

export const actionSetWorkingState = (workingState) => {
  return {
    type: TASK_ACTION_SET_WORKING_STATE,
    payload: {
      workingState: workingState,
    },
  };
};

export const actionSetInitialLoadState = (workingState) => {
  return {
    type: TASK_ACTION_SET_INITIAL_LOAD_STATE,
    payload: {
      initialLoadState: workingState,
    },
  };
};

export const actionFetchActiveListsAndTasks = () => {
  return async (dispatch, getState) => {
    try {
      const result = await fetchAllActiveItems();

      if (result) {
        dispatch({
          type: TASK_ACTION_FETCH_ACTIVE_LISTS_TASKS,
          payload: {
            initialLoadState: STATE_FINISHED,
            workingState: STATE_FINISHED,
            orderedListIds: result.orderedListIds,
            lists: result.lists,
          },
        });
      }
    } catch (err) {
      dispatch(actionSetFeedbackMessage(err.message, true));
    }
  };
};

export const actionCreateTask = (listId, taskName) => {
  return async (dispatch, getState) => {
    try {
      const newTask = await createTask(listId, { name: taskName });
      dispatch({
        type: TASK_ACTION_ADD_TASK,
        payload: {
          listId: listId,
          newTask: newTask,
          workingState: STATE_FINISHED,
          feedbackMessage: {
            message: "Task added",
            isError: false,
            key: new Date(),
          },
        },
      });
    } catch (err) {
      dispatch(actionSetFeedbackMessage(err.message, true));
    }
  };
};

export const actionCompleteTask = (listId, taskId) => {
  return async (dispatch, getState) => {
    try {
      await completeTask(listId, taskId);

      dispatch({
        type: TASK_ACTION_COMPLETE_TASK,
        payload: {
          workingState: STATE_FINISHED,
          listId: listId,
          taskId: taskId,
          feedbackMessage: {
            message: "Task was marked as complete",
            isError: false,
            key: new Date(),
          },
        },
      });
    } catch (err) {
      dispatch(actionSetFeedbackMessage(err.message, true));
    }
  };
};

export const actionAddTaskDialogState = (dialogState) => {
  return {
    type: TASK_ACTION_ADD_TASK_DIALOG_STATE,
    payload: dialogState,
  };
};

export const actionReOrderTasks = (listId, orderedTaskList) => {
  return async (dispatch, getState) => {
    try {
      await updateTaskPriorities(listId, { taskIdList: orderedTaskList });

      dispatch({
        type: TASK_ACTION_REORDER_TASKS,
        payload: {
          listId: listId,
          taskIdList: orderedTaskList,
          workingState: STATE_FINISHED,
          feedbackMessage: {
            message: "Tasks reordered successfully",
            isError: false,
            key: new Date(),
          },
        },
      });
    } catch (err) {
      dispatch(actionSetFeedbackMessage(err.message, true));
    }
  };
};
